/* Hook DisplaySlider */
.prismic-slider {
  padding: 4.5rem 1rem 6rem;
  position: relative;
  text-align: center;

  @media screen and (min-width: $md-width) {
    padding: 6rem 1rem;
  }

  &_ctn {
    position: relative;
    overflow: hidden;
    padding-bottom: 120%;

    @media (min-width: $sm-width + 1) {
      padding-bottom: 0;
      height: 272px;
    }

    @media (min-width: $md-width) {
      height: 338px;
    }

    @media (min-width: $lg-width) {
      height: 413px;
    }

    .owl-carousel {
      @include poa(0, 0, 0, 0);
    }
  }

  &_img {
    height: auto;
    margin: 0 auto;
    width: 100%;
  }
}

.owl-carousel.owl-loaded + .prismic-slider--initial {
  display: none;
}
