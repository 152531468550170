.prismic-promo {
  background-color: $red-dark;
  color: $white;
  display: block;
  font-weight: 900;
  margin: 1.5rem -1.5rem 3rem -1.5rem;
  padding: 2.5rem 1rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  @media screen and (min-width: $sm-width) {
    margin: 1.5rem 0 3rem 0;
  }

  &_content {
    font-size: 2rem;
    
    @media screen and (min-width: $md-width) {
      font-size: 3rem;
    }
  }

  h4 {
    margin-bottom: 0.5rem;

    @media screen and (min-width: $md-width) {
      font-size: 1.8rem;
    }
  }

  strong {
    border-color: inherit;
    border-radius: 0.8rem;
    border-style: solid;
    border-width: 0.1rem;
    display: inline-block;
    padding: 0 0.5rem;

    @media screen and (min-width: $md-width) {
      padding: 0.4rem 1.2rem;
    }
  }

  em {
    display: block;
    font-size: 0.6em;
    font-style: italic;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 1rem;

    @media screen and (min-width: $md-width) {
      font-size: 0.4em;
    }
  }

  &:first-of-type {
    background-color: $yellow-dark;
    color: $black;
  }
}
