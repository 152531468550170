.layered-home {
  .layered-choice-ctn {
    border: 0.1rem solid $grey-light;
    padding: 0;

    @media (min-width: $xs-width) {
      &:not(:first-child) {
        border-left: 0;
      }
    }
  }

  .layered-choice-btn-ctn {
    padding: 0;

    .button {
      border-radius: 0;
      font-size: 1.5rem;
      height: 5.6rem;
      padding: 2rem 4rem 0 2rem;
      white-space: nowrap;
      width: 100%;

      @media (min-width: $xl-width) {
        font-size: 1.6rem;
      }

      .icon {
        @include poa(50%, 1.5rem);
        font-size: 1.2rem;
        transform: translateY(-50%);

        @media (min-width: $xl-width) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .custom-select {
    & > span {
      border: 0;
      border-radius: 0;
      height: 5.5rem;
      line-height: 1;
      padding: 2rem 4rem 0 1.5rem;
    }

    li {
      padding-left: 2rem;

      &:first-child {
        font-weight: 700;
        padding-left: 1rem;
      }
    }
  }
}
