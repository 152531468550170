.configurator-cart {
  &__title {
    font-size: 20px;
    font-weight: bold;
  }

  &__img {
    display: block;
    font-size: 60px;
    margin: 0 auto;
  }

  .icon-edit {
    font-size: 18px;
  }

  &__delete {
    top: 50%;
    transform: translateY(-50%);
  }

  &__products {
    padding: 1rem 0 1.2rem 0;

    @media (min-width: $md-width) {
      padding: 0 0 0 10rem;
    }

    @media (min-width: $lg-width) {
      padding-left: 14rem;
    }

    .cart_quantity_input {
      &.disabled {
        background-color: #e2e7e8;
        border: 0;
        padding-right: 2px;
        width: 4rem;

        @media (min-width: $md-width) {
          width: 7rem;
        }
      }
    }

    .shopping-cart-product-img-ctn {
      margin-right: 5px;
      order: 1;
    }
  }

  .shopping-cart-product--conf {
    .shopping-cart-product-prices {
      background-color: transparent;
      flex-basis: auto;
      flex-grow: 0;
      justify-content: flex-end;
      padding-right: 0;
    }

    & + .shopping-cart-product--conf {
      border-top: 1px solid $grey-light;
      margin-top: 20px;
      padding-top: 10px;
    }
  }

  .accordion-trigger {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;

    &.open {
      .icon {
        &::before {
          content: "\e91f";
        }
      }
    }
  }

  .accordion-content {
    display: none;

    &.open {
      display: block;
    }
  }
}
